import logo from './logo.svg';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AddUnit from './pages/AddUnit/AddUnit';
import UpdateUnit from './pages/UpdateUnit/UpdateUnit';
import LoginPage from './pages/LoginPage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { logout } from './store/slices/authSlice';
import './App.css';

function App() {
  // const dispatch = useDispatch()
  const { userData } = useSelector((state) => state?.auth)
  const isUserData = !!Object.keys(userData).length
  // const expiresIn = 1714300349; // Your expires_at value
  // const expiresIn = userData?.expires_at; // Your expires_at value
  // const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

  // const remainingTime = expiresIn - currentTime;
  // console.log({ remainingTime })
  // useEffect(() => {
  //   if (remainingTime <= 0) {
  //     dispatch(logout())
  //   }
  // }, [userData])

  if (isUserData) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/add-unit" element={<AddUnit />} />
          <Route path="/update-unit/:id" element={<UpdateUnit />} />
        </Routes>
      </Router>
    );
  }
  else {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    );
  }
}

export default App;

// import { Box, Input } from '@mui/material'
// import React, { useState } from 'react'

// const LogoInput = ({
//     label,
//     placeholder,
//     name,
//     error,
//     setFieldValue,
//     value,
//     // setLogoPreview,
//     setFormValues,
//     formValues,
//     item
//     // setFile
// }) => {
//     const { brands } = formValues
//     // console.log({ item, brands, formValues })
//     const [logoPreview, setLogoPreview] = useState(null)
//     const handleChange = event => {
//         console.log({event})
//         // const tempFormVals = {...formValues}
//         const tempBrands = [...brands]
//         const brandIndex = tempBrands.findIndex((el) => el.id === item.id)
//         const file = event.target.files[0]
//         tempBrands[brandIndex].brand_logo = file
//         if (file) {
//             const reader = new FileReader();
//             reader.onload = () => {
//                 setLogoPreview(reader.result)
//                 tempBrands[brandIndex].brand_logo_preview = reader.result

//                 setFormValues('brands', tempBrands)
//             }
//             reader.readAsDataURL(file)
//         }
//     }
//     return (
//         <Box flex={1} sx={{
//             border: 'solid 1px #ccc',
//             p: '5px'
//         }}
//             display={'flex'} justifyContent="center" alignItems={"center"}
//         >
//             <label htmlFor={item.id}>
//                 {item?.brand_logo_link ? <img src={`https://qbmzpaginzcxohwdlabh.supabase.co/storage/v1/object/public/brands/${item?.brand_logo_link}`} style={{ width: '20px', height: '20px' }} /> : 'Insert Logo'}
//             </label>
//             <Input
//                 sx={{
//                     display: 'none'
//                 }}
//                 id={item.id}
//                 type="file"
//                 onChange={handleChange}
//             />
//         </Box>
//     )
// }

// export default LogoInput

import { Box, Input } from '@mui/material';
import React, { useEffect, useState } from 'react';

const LogoInput = ({
    setFormValues,
    formValues,
    item,
    setPreview
}) => {
    const { brands } = formValues;
    const [logoPreview, setLogoPreview] = useState(null);

    useEffect(() => {
      setLogoPreview(item.brand_logo_preview)
    }, [item.brand_logo_preview])
    

    const handleChange = (event) => {
        const tempBrands = [...brands];
        const brandIndex = tempBrands.findIndex((el) => el.id === item.id);
        const file = event.target.files[0];

        if (file) {
            // Update the `brand_logo` with the new file
            tempBrands[brandIndex].brand_logo = file;

            // Use FileReader to create a preview
            const reader = new FileReader();
            reader.onload = () => {
                setLogoPreview(reader.result);
                setPreview?.(reader.result);
                tempBrands[brandIndex].brand_logo_preview = reader.result;

                // Update the form values
                setFormValues('brands', tempBrands);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Box
            flex={1}
            sx={{
                border: 'solid 1px #ccc',
                p: '5px',
            }}
            display={'flex'}
            justifyContent="center"
            alignItems={"center"}
        >
            {/* Ensure `htmlFor` matches the input `id` */}
            <label htmlFor={`logo-input-${item.id}`}>
                {(logoPreview) ? (
                    <img
                        src={logoPreview}
                        alt="Preview"
                        style={{ width: '20px', height: '20px' }}
                    />
                ) : item?.brand_logo_link ? (
                    <img
                        src={`https://qbmzpaginzcxohwdlabh.supabase.co/storage/v1/object/public/brands/${item?.brand_logo_link}`}
                        alt="Brand Logo"
                        style={{ width: '20px', height: '20px' }}
                    />
                ) : (
                    'Insert Logo'
                )}
            </label>
            <Input
                sx={{
                    display: 'none',
                }}
                id={`logo-input-${item.id}`}
                type="file"
                onChange={handleChange}
            />
        </Box>
    );
};

export default LogoInput;

import { Box, Button, Typography, Input } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/slices/authSlice'
import { isTokenExpired } from '../utils/checkAuthorization'
import { supabase } from '../utils/supabase'
import CheckboxInput from './ui-elements/CheckboxInput'
import ColorInput from './ui-elements/ColorInput'
import FileInput from './ui-elements/FileInput'
import TextInput from './ui-elements/TextInput'
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { FaTrash } from 'react-icons/fa6'
import LogoInput from './ui-elements/LogoInput'


const Form = ({
    fields,
    schema,
    setCurrentStep,
    currentStep,
    setFormValues,
    setLogoPreview,
    isLastStep,
    formValues,
    formId,
    setActiveBrand
}) => {
    const params = useParams()

    const [openDeleteToastSuccess, setOpenDeleteToastSuccess] = useState(false);
    const [openDeleteToastFailed, setOpenDeleteToastFaied] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const { brandsFields } = useSelector((state) => state.brandsFields)
    const handleOpenSuccessToast = () => {
        setOpenDeleteToastSuccess(true);
    };

    const handleCloseSuccessToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteToastSuccess(false);
    };
    const handleOpenFailedToast = () => {
        setOpenDeleteToastFaied(true);
    };

    const handleCloseFailedToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteToastFaied(false);
    };

    const dispatch = useDispatch()
    const { userData } = useSelector((state) => state?.auth)
    const [file, setFile] = useState(null)
    const uploadImage = async (item) => {
        if (!item?.brand_logo) {
            console.error('No brand_logo found:', item);
            return null;
        }

        try {
            const { data, error } = await supabase.storage
                .from('brands') // Replace 'brands' with your bucket name
                .upload(`images/${uuidv4()}-${item.brand_logo.name}`, item.brand_logo, {
                    upsert: true
                });

            if (error) {
                console.error('Error uploading file:', error.message);
                return null;
            }

            return data.path; // Return the path of the uploaded image
        } catch (error) {
            console.error('Unexpected error:', error.message);
            return null;
        }
    };
    const {
        handleSubmit,
        values,
        errors,
        setFieldValue,
        touched
    } = useFormik({
        validationSchema: schema,
        initialValues: {
            ...formValues,
            button_color: '#000000',
            button_text_color: "#ffffff",
            title_background_color: '#ffffff',
            title_text_color: '#000000',
            spon_back_background_color: '#800000',
        },
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async (formVals) => {
            setFormValues({ ...formVals });

            if (isLastStep) {
                setIsLoading(true)
                // Separate brands based on whether they need logo uploads
                const prevBrands = formVals.brands.filter((el) => !el.brand_logo);
                const brandsLogoToUpload = formVals.brands
                    .filter((el) => el.brand_logo)
                    .map((item) => ({
                        id: item.brand_id || item.id,
                        brand_title: item.brand_title,
                        brand_logo: item.brand_logo,
                        brand_logo_preview: item.brand_logo_preview,
                        brand_link: item.brand_link,
                    }));

                try {
                    // Upload logos for brands that need it
                    const uploadedLogos = await Promise.all(
                        brandsLogoToUpload.map(async (item) => {
                            try {
                                const res = await uploadImage(item);
                                return {
                                    brand_link: item?.brand_link,
                                    brand_title: item?.brand_title,
                                    brand_id: item?.id,
                                    brand_logo_link: res,
                                };
                            } catch (err) {
                                console.error(`Error uploading logo for brand:`, item, err);
                                return null; // Return null for failed uploads
                            }
                        })
                    );

                    // Combine previous brands and successfully uploaded brands
                    const finalBrands = [
                        ...prevBrands,
                        ...uploadedLogos.filter((brand) => brand !== null), // Remove failed uploads
                    ];

                    if (finalBrands.length === formVals.brands.length) {
                        // Update the record in Supabase
                        await supabase
                            .from('ads')
                            .update({
                                ...formVals,
                                brands: finalBrands,
                            })
                            .eq('id', formVals.id).then(() => {
                                setIsLoading(false)
                                handleOpenSuccessToast();
                            });

                    } else {
                        setIsLoading(false)
                        console.error('Some brand logos failed to upload.');
                    }
                } catch (error) {
                    setIsLoading(false)
                    console.error('Error during brand update:', error);
                }
            } else {
                // Proceed to the next step if not the last
                setCurrentStep((ps) => ps + 1);
            }
        }
    })
    const isValidPrevBrands = (array) => {

        for (const obj of array) {
            // Check for new entries (client-side only)
            if ('id' in obj) {
                if (!obj.id || !obj.brand_title || !obj.brand_link || !obj.brand_logo) {
                    return false;
                }
            }
            // Check for existing entries (fetched from the database)
            else if ('brand_id' in obj) {
                if (!obj.brand_id || !obj.brand_title || !obj.brand_link || !obj.brand_logo_link) {
                    return false;
                }
            }
            // Handle unexpected objects (neither new nor from DB)
            else {
                console.error("Unexpected object structure", obj);
                return false;
            }
        }

        return true;
    };

    const handleAddRow = () => {
        if (isValidPrevBrands(values['brands'])) {
            const newBrand = {
                id: uuidv4(),
                brand_title: '',
                brand_logo: null,
                brand_logo_preview: null,
                brand_link: '',
                // isNew:false
            }
            setLogoPreview(null)
            setFieldValue('brands', [
                ...values?.['brands'],
                newBrand
            ])
            setActiveBrand(newBrand.id)
            const tempBrands = !!formValues?.brands?.length ? [...formValues?.brands] : []
            tempBrands.push(newBrand)

            setFormValues('brands', tempBrands)

        }
        else {
            alert('Please insert all values in previous brands')
        }
    }
    const handleUpdate = (e, id) => {
        const { value, name } = e.target
        const tempBrands = [...formValues?.['brands']]
        const currentBrandIndex = tempBrands.findIndex((el) => el.id === id)
        tempBrands[currentBrandIndex] = {
            ...tempBrands[currentBrandIndex],
            [name]: value
        }
        setFieldValue('brands', tempBrands)
        setFormValues('brands', tempBrands)
    }

    const handleDeleteBrand = (id) => {
        const tempBrands = [...formValues['brands']].filter((item) => item.id !== id)
        setFieldValue('brands', tempBrands)
        setFormValues('brands', tempBrands)
        const activeBrand = tempBrands[tempBrands.length - 1].brand_id || tempBrands[tempBrands.length - 1].id || ''
        setActiveBrand(activeBrand)
    }
    console.log({errors})
    return (
        <>
            <form onSubmit={handleSubmit}>
                {currentStep === 2 ? brandsFields.map(({ formFields }, index) => {
                    return <>
                        <Box>
                            <Box display='flex'>
                                {
                                    formFields.map((field, i) => {
                                        return <Typography flex={1} sx={{
                                            border: 'solid 1px #ccc',
                                            p: '5px'
                                        }} key={i}>{field.label}</Typography>
                                    })
                                }
                                <Typography flex={1} sx={{
                                    border: 'solid 1px #ccc',
                                    p: '5px'
                                }}>Action</Typography>
                            </Box>
                            <Box>
                                {
                                    !!formValues?.['brands']?.length && formValues?.['brands'].map((item, index) => {
                                        return <Box display={'flex'}>
                                            <LogoInput setPreview={setLogoPreview} formValues={formValues} item={item} setFormValues={setFormValues} />
                                            <Box flex={1} sx={{
                                                border: 'solid 1px #ccc',
                                                p: '5px',
                                                display: 'flex',
                                                alignItems: 'end'
                                            }}>
                                                <Input sx={{
                                                    border: 'none',
                                                    width: '100%',
                                                }} value={item?.brand_title} name={'brand_title'} onChange={(e) => handleUpdate(e, item.id)} />
                                            </Box>
                                            <Box flex={1} sx={{
                                                border: 'solid 1px #ccc',
                                                p: '5px'
                                            }}>
                                                <Input sx={{
                                                    border: 'none',
                                                    width: '100%'
                                                }} value={item?.brand_link} name={'brand_link'} onChange={(e) => handleUpdate(e, item.id)} />
                                            </Box>
                                            <Box display={'flex'} justifyContent="center" alignItems={"center"} flex={1} sx={{
                                                border: 'solid 1px #ccc',
                                                p: '5px'
                                            }}>
                                                <FaTrash onClick={() => handleDeleteBrand(item.id)} />
                                            </Box>
                                        </Box>
                                    })
                                }
                            </Box>
                        </Box>
                        <Button sx={{ mt: '20px', width: '100%' }} disabled={1 === 2} onClick={handleAddRow} variant='contained'>Add brand</Button>
                    </>
                })
                    : fields?.map(({ label, placeholder, type, name }) => {
                        if (type === 'checkbox') {
                            return <CheckboxInput
                                setFormValues={setFormValues}
                                label={label}
                                value={values[name]}
                                name={name}
                                setFieldValue={setFieldValue}
                                values={values}
                            />
                        }
                        else if (type === 'color') {
                            return <ColorInput
                                setFormValues={setFormValues}
                                label={label}
                                value={values[name]}
                                name={name}
                                setFieldValue={setFieldValue}
                            />
                        }
                        else if (type === 'file') {
                            return <FileInput
                                setFormValues={setFormValues}
                                label={label}
                                value={values[name]}
                                name={name}
                                setFieldValue={setFieldValue}
                                setLogoPreview={setLogoPreview}
                                setFile={setFile}
                            />
                        }
                        return (
                            <TextInput
                                setFormValues={setFormValues}
                                label={label}
                                placeholder={placeholder}
                                value={values[name]}
                                error={errors[name]}
                                name={name}
                                setFieldValue={setFieldValue}
                            />
                        )
                    })}
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Button sx={{ mt: '20px', width: '150px' }} disabled={currentStep === 1} onClick={() => setCurrentStep(ps => ps - 1)} variant='contained'>Back</Button>
                    {/* <Button sx={{ mt: '20px', width: '150px' }} type='submit' variant='contained'>{isLastStep ? 'Update' : 'Next'}</Button> */}
                    <Button disabled={isLoading} sx={{ mt: '20px', width: '150px' }} type='submit' variant='contained'>{isLastStep ? `${isLoading ? 'Updating' :'Update'}` : 'Next'}</Button>
                </Box>
            </form>
            <Snackbar open={openDeleteToastSuccess} autoHideDuration={6000} onClose={handleCloseSuccessToast}>
                <Alert
                    onClose={handleCloseSuccessToast}
                    severitFailed="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Item updated successfully
                </Alert>
            </Snackbar>
            <Snackbar open={openDeleteToastFailed} autoHideDuration={6000} onClose={handleCloseFailedToast}>
                <Alert
                    onClose={handleCloseFailedToast}
                    severitFailed="error"
                    variant="filled"
                    sx={{ width: '100%', bgcolor: 'red' }}
                >
                    Failed to update item
                </Alert>
            </Snackbar>
        </>
    )
}

export default Form